<template>
  <div class="ppt-player-container">
    <div class="ppt-player-main full-screen">
      <div
        class="ppt-player-module full-screen"
        @touchstart.passive="clickStart"
        @touchend.passive="clickEnd"
        @touchmove.passive="clickMove"
        v-for="(item, index) in data"
        :key="index"
        :class="{
          'ppt-player-module-select': index == curPage,
          ...getAnimaClass(index),
        }"
      >
        <img
          :src="item.content"
          alt=""
          class="ppt-player-module-content"
          v-if="isImage"
        />
        <slot
          :cItems="item"
          :cIndex="index"
          v-else-if="Math.abs(curPage - index) <= 1"
        />
      </div>
    </div>

    <div
      class="ppt-player-control full-screen global-center"
      @click.stop="stopUpClick"
      v-if="isHaveButton"
    >
      <div class="ppt-player-control-main">
        <div
          class="ppt-player-control-btn ppt-player-control-left"
          @click="goToPrepPage"
        >
          <img :src="require('@/assets/images/icon/left.png')" alt="" />
        </div>
        <div class="ppt-player-control-page">
          <span class="global-color">{{ curPage + 1 }}</span>
          <span>/</span>
          <span>{{ data.length }}</span>
        </div>
        <div
          class="ppt-player-control-btn ppt-player-control-right"
          @click="goToNextPage"
        >
          <img :src="require('@/assets/images/icon/right.png')" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElIcon } from "element-plus";
import { ArrowRightBold, ArrowLeftBold } from "@element-plus/icons";
export default {
  name: "ppt-player",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isHaveButton: {
      type: Boolean,
      default: true,
    },
    isImage: {
      type: Boolean,
      default: true,
    },
    cfg: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      config: {
        screen: {
          sliding: false, // 是否可以滑动
          page: false, // 是否可以左右翻页
          point: false, // 是否选择点击翻页
        },
        spacePoint: 5,
      },
      curPage: 0,
      prepPage: 0,
      startPoint: null,
    };
  },
  components: {
    ArrowRightBold,
    ArrowLeftBold,
    ElIcon,
  },
  created() {
    console.log(this.data);
    this.initConfig();
  },
  methods: {
    initConfig() {
      this.config.screen = {
        ...this.config.screen,
        ...this.cfg,
      };
    },
    getAnimaClass(index) {
      if (this.curPage == this.prepPage) return {};
      // 如果 当前页 大于上一页 则往左滑
      if (
        (this.curPage > this.prepPage &&
          !(this.curPage == this.data.length - 1 && this.prepPage == 0)) ||
        (this.curPage == 0 && this.prepPage == this.data.length - 1)
      ) {
        return {
          "ppt-player-module-to-left-show": this.curPage == index,
          "ppt-player-module-to-left-gone": this.prepPage == index,
        };
      }
      // 当前页小于上一页，往右滑
      return {
        "ppt-player-module-to-right-show": this.curPage == index,
        "ppt-player-module-to-right-gone": this.prepPage == index,
      };
    },
    getPointList() {
      // curpage 小于 4 则 显示 下标 2，3，4
      if (this.curPage < this.config.spacePoint - 1) return [2, 3, 4];
      // curpage > data.list.length - 4 则显示下标 len-5,len-4,len-3
      if (this.curPage > this.data.length - this.config.spacePoint + 1)
        return [
          this.data.length - 5,
          this.data.length - 4,
          this.data.length - 3,
        ];
      return [this.curPage - 1, this.curPage, this.curPage + 1];
    },
    clickStart(event) {
      let client = event.changedTouches[0];
      this.startPoint = client;
    },
    clickMove(event) {},
    clickEnd(event) {
      if (!this.config.screen.sliding) return;
      let client = event.changedTouches[0];
      // if(client.clientX - this.startPoint.clientX)
      let space = client.clientX - this.startPoint.clientX;
      if (Math.abs(space) < 10) {
        this.selectIndex();
        return;
      }
      space > 0 ? this.goToPrepPage() : this.goToNextPage();
      this.startPoint = null;
    },
    selectIndex() {
      this.$emit("select", this.data[this.curPage]);
    },
    setPage(page) {
      this.prepPage = this.curPage;
      this.curPage = page;
    },
    stopUpClick(event) {
      event.preventDefault();
      event.cancelBubble = true;
    },
    goToPrepPage() {
      if (this.curPage == 0) {
        // this.setPage(this.data.length -1);
        return;
      }
      this.setPage(this.curPage - 1);
    },
    goToNextPage() {
      if (this.curPage >= this.data.length - 1) {
        // this.setPage(0)
        return;
      }
      this.setPage(this.curPage + 1);
    },
    turnPage(meetingInfo) {
      let index = meetingInfo.doctor_index == 0 ? 1 : meetingInfo.doctor_index;
      console.log(index);
      this.goToPage(index - 1);
    },
    goToPage(index) {
      this.setPage(index);
    },
  },
};
</script>
<style scoped>
.ppt-player-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 100%;
  /* 高度和宽度相等 */
}
.ppt-player-module {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 700;
  overflow: hidden;
}
.ppt-player-control {
  position: absolute;
  left: 0;
  bottom: 27px;
  z-index: 1600;
  height: 28px;
}
.ppt-player-control-main {
  width: 125px;
  height: 28px;
  background: rgba(0, 0, 0, 0.71);
  border-radius: 28px;
  padding: 0 14px;
  display: flex;
}
.ppt-player-control-left {
  justify-content: flex-start;
}
.ppt-player-control-page {
  flex: 1 0 0;
  margin: 0 10px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  color: #fff;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ppt-player-control-page span:nth-child(2) {
  margin: 0 2px;
}
.ppt-player-control-right {
  justify-content: flex-end;
}
.ppt-player-control-btn {
  display: flex;
  align-items: center;
}
.ppt-player-control-btn img {
  height: 50%;
  width: auto;
  width: 7px;
}

.ppt-player-module-select {
  z-index: 900;
}
.ppt-player-footer-page-item-select {
  background: #a74981;
}
.ppt-player-module-to-right-show {
  z-index: 900;
  animation-timing-function: linear;
  animation: -right-show 0.5s;
  -webkit-animation: -right-show 0.5s; /* Safari 和 Chrome */
}
.ppt-player-module-to-right-gone {
  z-index: 800;
  animation-timing-function: linear;
  animation: -right-gone 0.5s;
  -webkit-animation: -right-gone 0.5s; /* Safari 和 Chrome */
}
.ppt-player-module-to-left-show {
  z-index: 900;
  animation-timing-function: linear;
  animation: -left-show 0.5s;
  -webkit-animation: -left-show 0.5s; /* Safari 和 Chrome */
}
.ppt-player-module-to-left-gone {
  z-index: 800;
  animation-timing-function: linear;
  animation: -left-gone 0.5s;
  -webkit-animation: -left-gone 0.5s; /* Safari 和 Chrome */
}
@keyframes -left-gone {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes -left-show {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}
@keyframes -right-gone {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
@keyframes -right-show {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}
.ppt-player-module-content {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>