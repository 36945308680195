<template>
  <div class="icon-button-container">
    <img class="icon-button-img" :src="src" />
    <span>{{ title }}</span>
  </div>
</template>
<script>
export default {
  name:"icon-button",
  props:{
    src:String,
    title:String
  },
}
</script>
<style>
.icon-button-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size:14px;
  color:#606060;
}
.icon-button-img{
  width:21px;
  height:auto;
  margin-bottom:2px;
}
</style>