<template>
  <div class="detail-courseware-container full-screen">
    <div class="detail-courseware-main">
      <div class="detail-courseware-title global-color">
        {{ data.courseware_title }}
      </div>
      <div class="detail-courseware-date">{{ data.publish_date }}</div>
      <img
        @click="handleLike"
        class="detail-courseware-like"
        :src="
          require(`@/assets/img/${
            countInfo?.is_collect ? 'liking' : 'like'
          }.png`)
        "
        alt=""
      />
      <div class="detail-courseware-player">
        <div class="global-position full-screen">
          <PPTPlayer
            :data="data.courseware"
            :cfg="{
              sliding: true,
              page: true,
              point: true,
            }"
          >
            <template v-slot="{ cItems, cIndex }"> </template>
          </PPTPlayer>
        </div>
      </div>
      <div class="detail-courseware-button-group">
        <CustomButton class="detail-courseware-button" @click="doCommit"
          >确认选择</CustomButton
        >
        <CustomButton class="detail-courseware-button" @click="$router.back()"
          >返回上一页</CustomButton
        >
      </div>
    </div>
  </div>
</template>
<script>
import PPTPlayer from "@/components/player/PPTPlayer";
import CustomButton from "@/components/unit/CustomButton";
import IconButton from "@/components/unit/ButtonForIcon";
export default {
  name: "preview-courseware",
  components: {
    PPTPlayer,
    CustomButton,
    IconButton,
  },
  props: {},
  data() {
    return {
      list: [],
      data: {},
      countInfo: {},
    };
  },
  created() {
    this.initData();
    this.initCountInfo();
  },
  methods: {
    initData() {
      let url = this.$tools.getURL(this.$urls.literature.detail, {
        id: this.$route.query.id,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
        })
        .catch(() => {});
    },
    doCommit() {
      this.$tools.setStorage("coursewareData", {
        literature_id: this.data.id,
        meet_topics: this.data.courseware_title,
        courseware_info: this.data.courseware,
        courseware_url: this.data.literature_pdf_url,
      });

      this.$router.push({
        path: "/meetInfo",
        query: {
          user_id: this.$route.query.user_id,
          meet_id: this.$route.query.meet_id,
          form: this.$route.query.form,
        },
      });
    },
    initCountInfo() {
      let url = this.$tools.getURL(this.$urls.literature.countInfo, {
        id: this.$route.query.id,
        type: this.$store.state.common.userInfo.id
          ? 0
          : this.$store.state.common.salesInfo.phone
          ? 1
          : 0,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.countInfo = res.data;
        })
        .catch(() => {});
    },
    handleLike() {
      if (
        this.$store.state.common.userInfo.id ||
        this.$store.state.common.salesInfo.phone
      ) {
        let url = this.$tools.getURL(this.$urls.literature.collect, {
          id: this.$route.query.id,
          type: this.$store.state.common.userInfo.id
            ? 0
            : this.$store.state.common.salesInfo.phone
            ? 1
            : 0,
        });
        this.$axios
          .get(url)
          .then((res) => {
            console.log("res :>> ", res);
            this.$tips.success({ text: res.msg });
            this.countInfo.is_collect = this.countInfo.is_collect ? 0 : 1;
          })
          .catch(() => {});
      } else {
        this.$router.push({
          path: "/doctorLogin",
          query: {
            form: this.$route.path,
            id: this.$route.query.id,
          },
        });
      }
    },
  },
};
</script>
<style>
.detail-courseware-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0 20px;
  /* align-items: center; */
}

.detail-courseware-title {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 0 40px;
}

.detail-courseware-date {
  font-weight: 400;
  font-size: 13px;
  color: #606060;
  text-align: center;
  padding: 5px 0;
}

.detail-courseware-player {
  width: 100%;
  padding-bottom: 100%;
  /* 高度和宽度相等 */
  position: relative;
}

.detail-courseware-button-group {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.detail-courseware-button {
  width: 120px;
  font-size: 14px;
  padding: 5px;
}
.detail-courseware-button:last-child {
  margin-top: 10px;
}
.detail-courseware-main {
  position: relative;
}
.detail-courseware-like {
  width: 25px;
  position: absolute;
  right: 9px;
  top: -4px;
}
</style>